<template>
  <div>
    <v-tour :options="tourOptions" name="reports" :steps="steps"></v-tour>
    <div class="title-page flex justify-between text-center">
      <div>
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3
          class="font-bold mb-2 ml-3"
          style="display: inline-block; font-size: 1.125rem"
        >
          {{ name }}
        </h3>
      </div>
    </div>
    <div class="line"></div>
    <div class="body-excel" data-reports-tour-step="1">
      <vs-alert :active="error_msg != ''" color="danger" icon="new_releases">
        <span>{{ error_msg }}</span>
      </vs-alert>
      <vs-alert
        :active="success_api == 0 && messageApi != ''"
        color="danger"
        icon="new_releases"
      >
        <span>{{ messageApi }}</span>
      </vs-alert>
      <vs-alert
        :active="success_api == 1 && messageApi != ''"
        color="success"
        icon="done_all"
      >
        <span>{{ messageApi }}</span>
      </vs-alert>
      <div class="excel">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="88.795"
          height="87.175"
          viewBox="0 0 88.795 87.175"
        >
          <path
            id="Icon_simple-microsoftexcel"
            data-name="Icon simple-microsoftexcel"
            d="M87.141,11H57.395V16.5h8.769V25.21H57.395v2.775h8.769V36.7H57.395v2.853h8.769V47.8H57.395v3.3h8.769v8.265H57.395v3.3h8.769v8.313H57.395v6.064H87.141c.47-.141.862-.7,1.177-1.658a8.113,8.113,0,0,0,.477-2.353V12c0-.474-.163-.758-.477-.858A4.07,4.07,0,0,0,87.141,11ZM83.282,70.983H69.012V62.677h14.27v8.306Zm0-11.61H69.012V51.1h14.27Zm0-11.573H69.012v-8.21h14.27V47.8Zm0-11.1H69.012V27.992h14.27V36.7Zm0-11.54H69.012V16.5h14.27v8.658ZM0,9.622v68.82L52.389,87.5V.328L0,9.652Zm31.052,52.06q-.3-.81-2.8-6.893c-1.661-4.055-2.664-6.415-2.956-7.089H25.2l-5.62,13.375-7.511-.507,8.909-16.649L12.82,27.27l7.659-.4L25.54,39.894h.1l5.716-13.619,7.914-.5L29.846,43.794l9.712,18.381-8.506-.5Z"
            transform="translate(0 -0.328)"
            fill="#21b463"
          />
        </svg>
      </div>
      <p class="text">
        إختر التاريخ المراد تحميل تقرير {{ name }} الخاصة بك فيه ثم قم بتحميل
        ملف الاكسيل
      </p>

      <div class="inputs">
        <div class="input">
          <label class="font-bold">{{ $t("fromDuration") }} :</label>
          <datepicker
            :language="ar"
            :placeholder="$t('chooseDate')"
            v-model="fromDate"
            format="dd-MM-yyyy"
          ></datepicker>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
            >
              <g id="calendar" transform="translate(-1.987 -0.541)">
                <rect
                  id="Rectangle_797"
                  data-name="Rectangle 797"
                  width="14"
                  height="14"
                  rx="2"
                  transform="translate(2.987 3.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_395"
                  data-name="Line 395"
                  y2="4"
                  transform="translate(12.987 1.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_396"
                  data-name="Line 396"
                  y2="4"
                  transform="translate(6.987 1.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_397"
                  data-name="Line 397"
                  x2="14"
                  transform="translate(2.987 8.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </i>
        </div>
        <div class="input">
          <label class="font-bold">{{ $t("toDuration") }} :</label>
          <datepicker
            :language="ar"
            :placeholder="$t('chooseDate')"
            v-model="toDate"
            format="dd-MM-yyyy"
          ></datepicker>
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
            >
              <g id="calendar" transform="translate(-1.987 -0.541)">
                <rect
                  id="Rectangle_797"
                  data-name="Rectangle 797"
                  width="14"
                  height="14"
                  rx="2"
                  transform="translate(2.987 3.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_395"
                  data-name="Line 395"
                  y2="4"
                  transform="translate(12.987 1.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_396"
                  data-name="Line 396"
                  y2="4"
                  transform="translate(6.987 1.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_397"
                  data-name="Line 397"
                  x2="14"
                  transform="translate(2.987 8.541)"
                  fill="none"
                  stroke="#6E6B7B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </i>
        </div>
      </div>

      <div class="flex btn-excel justify-start flex-wrap" v-if="isOrder">
        <p class="selectDate font-bold">{{ $t("basedOn") }}</p>
        <span style="display: flex; align-items: center" class="mt-2">
          <vs-radio v-model="valueDate" class="mr-4 font-10" vs-value="1"
            ><span :class="{ 'text-primary': valueDate == 1 }"
              >تاريخ الإرسال</span
            ></vs-radio
          >
          <vs-radio v-model="valueDate" class="mr-4" vs-value="2"
            ><span :class="{ 'text-primary': valueDate == 2 }"
              >تاريخ التنفيذ</span
            ></vs-radio
          >
          <vs-radio v-model="valueDate" class="mr-4" vs-value="3"
            ><span :class="{ 'text-primary': valueDate == 3 }"
              >تاريخ التسجيل</span
            ></vs-radio
          >
        </span>
      </div>
      <div class="flex btn-excel justify-end">
        <button
          @click="fetchData()"
          class="excel-donwload font-bold"
          v-if="!isLoad"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
            width="12.221"
            height="16.295"
            viewBox="0 0 12.221 16.295"
          >
            <path
              id="Icon_awesome-file-download"
              data-name="Icon awesome-file-download"
              d="M7.129,4.328V0H.764A.762.762,0,0,0,0,.764V15.531a.762.762,0,0,0,.764.764H11.457a.762.762,0,0,0,.764-.764V5.092H7.893A.766.766,0,0,1,7.129,4.328Zm2.433,6.727L6.493,14.1a.543.543,0,0,1-.765,0L2.66,11.055a.509.509,0,0,1,.358-.871H5.092V7.638A.509.509,0,0,1,5.6,7.129H6.62a.509.509,0,0,1,.509.509v2.546H9.2A.509.509,0,0,1,9.562,11.055ZM12,3.342,8.883.223A.763.763,0,0,0,8.342,0H8.147V4.074h4.074V3.88A.761.761,0,0,0,12,3.342Z"
              fill="#fff"
            />
          </svg>
          {{ $t("downloadReport") }}
        </button>
        <button class="excel-donwload" v-else>
          <SpinnerColor />
        </button>
        <router-link to="/">
          <button class="excel-back font-bold">
            {{ $t("back") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { en, ar } from "vuejs-datepicker/dist/locale";
import { mapActions, mapGetters } from "vuex";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import tour from "@/mixins/tour.js";

export default {
  mixins: [tour],
  components: {
    Datepicker,
    SpinnerColor,
  },
  data() {
    return {
      ar: ar,
      valueDate: 1,
      DatePickerFormat: "dd/MM/yyyy",
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      fromDate: "",
      toDate: "",
      error_msg: "",
      steps: [
        {
          target: '[data-reports-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "تفاصيل التقارير",
          },
          content: `من هنا يمكنك تحميل تقرير طرودك (الاسبوعي / الشهري)`,
          params: {
            placement: "top",
            enableScrolling: false,
          },
        },
        {
          target: '[data-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "اضف طرد",
          },
          content: `من هنا يمكنك اضافة طرد جديد للشحن وايضاً يمكن اضافة تفاصيل اخري في هذة الفقرة النصية`,
        },
      ],
    };
  },
  props: {
    name: {
      type: String,
    },
    isOrder: {
      type: Boolean,
      default: false,
    },
    isLoad: {
      type: Boolean,
    },
    messageApi: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("dataList", ["message", "success_api"]),
  },
  methods: {
    ...mapActions("dataList", ["excelOrder"]),
    async fetchData() {
      if (this.fromDate == "" || this.toDate == "") {
        return (this.error_msg = "برجاء ادخال المدة الناقصة");
      } else if (this.fromDate > this.toDate) {
        return (this.error_msg =
          "يجب ان يكون تاريخ (النهاية) أحدث من تاريخ (البداية)");
      } else {
        let date_from = new Date(this.fromDate).toISOString().split("T")[0];
        let date_to = new Date(this.toDate).toISOString().split("T")[0];
        let obj = {
          dateFrom: date_from,
          dateTo: date_to,
        };
        if (this.isOrder) obj.type = this.valueDate;
        await this.$emit("downLoadExcel", obj);
        this.error_msg = "";
      }
    },
  },
  mounted() {
    if (this.runWalkthrough()) {
      // this.startTour("reports");
      setTimeout(() => {
        window.scrollTo(0, 20);
      }, 500);
      this.$watch(
        () => {
          return this.$tours.reports.currentStep;
        },
        (val) => {
          this.$store.dispatch("updateStepTour", val);
          if (val == 1) {
            const nextStep = document.querySelector(".v-step__button");
            nextStep.addEventListener("click", () => {
              this.$router.push({ path: "/addOrder" });
            });
          }
        }
      );
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.inputs {
  display: flex;
  justify-content: space-between;
  width: 53.4375;
  margin: auto;
  margin-bottom: 1.5625rem;
  .vdp-datepicker {
    div {
      input {
        width: 25.9375rem;
        border: 0.0625rem solid #d8d6de;
        border-radius: 0.3125rem;
        height: 2.4375rem;
        padding: 0 0.75rem;
      }
      input::placeholder {
        color: #6e6b7b;
        opacity: 50%;
        font-size: 0.75rem;
        line-height: 1.3125rem;
        font-family: "cairo";
      }
    }
  }
  .input {
    position: relative;
    i {
      position: absolute;
      right: 0.75rem;
      top: 2.375rem;
      opacity: 85%;
    }
  }
}
.vdp-datepicker__calendar {
  .cell {
    border-radius: 50%;
    height: 14%;
    &:hover {
      border-color: $mainColor1 !important;
    }
  }
  .selected {
    background-color: $mainColor1;
    color: #fff;
    &:hover {
      background-color: $mainColor1;
    }
  }
}

@media (min-width: 768px) and (max-width: 1094px) {
  .inputs {
    display: block !important;
    width: 100% !important;
    .vdp-datepicker {
      div {
        input {
          width: 100% !important;
          margin-bottom: 1.125rem;
        }
      }
    }
  }
  .btn-excel {
    width: 100% !important;
  }
}

@media (min-width: 300px) and (max-width: 767px) {
  .title-page {
    h3 {
      font-size: 0.75rem !important;
    }
    .show-orders {
      font-size: 0.75rem !important;
      width: 8.125rem !important;
    }
  }
  .inputs {
    display: block !important;
    width: 100% !important;
    .vdp-datepicker {
      div {
        input {
          width: 100% !important;
          margin-bottom: 1.125rem;
        }
      }
    }
  }
  .btn-excel {
    width: 100% !important;
  }
  .body-excel {
    .text {
      padding: 0 0.9375rem !important;
      font-size: 0.875rem !important;
      margin-bottom: 0.3125rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
.loader {
  border: 2px solid #fff;
  border-top: 2px solid #31006f1a;
}
.title-page {
  h3 {
    font-size: 1.125rem;
    line-height: 2.9375rem;
    color: #212121;
    font-weight: bold;
  }
}
.line {
  border-bottom: 0.03125rem solid #70707061;
  opacity: 50%;
  margin-bottom: 1.3rem;
}
.show-orders {
  cursor: pointer;
  width: 9.875rem;
  height: 2.375rem;
  background-color: $mainColor2;
  color: $mainColor1;
  font-size: 0.875rem;
  font-family: "cairo";
  line-height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.body-excel {
  width: fit-content;
  margin: auto;
  padding: 6px 4px;
  .excel {
    background-color: #28c76f2e;
    width: 10.4375rem;
    height: 10.4375rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.75rem auto;
    cursor: pointer;
  }
  .text {
    margin: auto;
    text-align: center;
    width: 22.75rem;
    font-family: "cairo";
    color: #6e6b7b;
    font-size: 1rem;
    line-height: 1.3125rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  .inputs {
    display: flex;
    justify-content: space-between;
    width: 53.4375rem;
    margin: auto;
    margin-bottom: 1.5625rem;
    label {
      font-size: 0.875rem;
      color: #212121;
      line-height: 1.3125rem;
      margin-bottom: 0.4375rem;
      display: block;
      font-weight: 600;
    }
  }
  .btn-excel {
    width: 53.4375rem;
    margin: auto;
    .excel-donwload {
      background-color: #28c76f;
      color: #fff;
      width: 9.6875rem;
      height: 2.375rem;
      font-family: "cairo";
      font-size: 0.875rem;
      line-height: 1.3125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 0.4rem;
      margin-right: 1.5rem;
      cursor: pointer;
    }
    .excel-back {
      width: 6rem;
      height: 2.375rem;
      border: 0.0625rem solid #707070;
      box-shadow: 0rem 0.25rem 1.5rem #0000000d;
      color: #212121;
      font-family: "cairo";
      font-size: 0.875rem;
      line-height: 1.3125rem;
      background-color: transparent;
      border-radius: 0.4rem;
      cursor: pointer;
    }
  }
  input[type="radio"] {
    display: none;
  }
  .checked,
  .unchecked {
    line-height: 0rem;
    margin-right: 0.25rem;
    cursor: pointer;
  }
  .selectDate {
    width: 100%;
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
    color: #212121;
    line-height: 1.3125rem;
    font-weight: 600;
  }
}
</style>
